import React, { useEffect, useState, useCallback } from 'react'
import {
  Dialog,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Tooltip,
  Tab,
  TextField,
  Fade,
  Select,
  Box,
} from '@material-ui/core'
import { Lock, LockOpen, FileCopy } from '@material-ui/icons'
import { withRouter } from 'react-router-dom'
import DeviceModalTemplate from 'components/templates/DeviceModalTemplate'
import { connect } from 'react-redux'
import {
  Button,
  Tabs,
  Loader,
  useInput,
  formatDeviceId,
  Container,
} from 'sputnik-ui'
import ym from 'react-yandex-metrika'
import styled from 'styled-components'
import { copyToClipboard, formatDate } from 'utils/helpers'
import { useTranslation } from 'react-i18next'

import OnlineIcon from 'components/atoms/OnlineIcon'
import FullscreenExitButton from 'components/atoms/FullscreenExitButton'
import FullscreenDialogTitle from 'components/atoms/FullscreenDialogTitle'

import OpenTimeSelect from 'components/atoms/OpenTimeSelect'
import CommutatorScheme from 'components/atoms/CommutatorScheme'
import ConfirmActionModal from 'components/organisms/ConfirmActionModal'

import {
  getCamera,
  fetchIntercomByUuid,
  openDoor,
  unlinkDevice,
  updateDeviceSettings,
  getIntercomsByAddress,
  fetchSoundPresets,
} from 'store/devices/operations'
import {
  clearCurrentDevice,
  updateCurrentIntercomStatus,
  updateCurrentIntercomDoorStatus,
} from 'store/devices/actions'
import { fetchGate } from 'store/gates/operations'
import useIntercomStatus from 'hooks/useIntercomStatus'
import { showInfoMessage } from 'store/ui/actions'
import { fixStreamCamera } from 'features/Cameras/camerasSlice'
import ControlCollectKeys from '../ControlCollectKeys'
import HouseRect from './HouseRect'
import { ConnectSecondDoorModal } from './ConnectSecondDoorModal'
import { ExtraKeyReader } from './ExtraKeyReader'
import { OpenSecondDoorButton } from './OpenSecondDoorButton'

const DeviceModal = ({
  match,
  history,
  dispatch,
  camera,
  device,
  devicesError,
  gate,
  loading,
  soundPresets,
}) => {
  const {
    params: { device: deviceUuid, gate: gateAddress },
  } = match
  const gateUuid = gate?.intercom_uuid
  const uuidOfCurrentDevice = deviceUuid || gateUuid

  const handleClose = useCallback(() => {
    if (device)
      history.length > 0
        ? history.goBack()
        : history.push(
            device.address_type === 'gate' ? `/` : `/e/${device.address_uuid}`
          )
  }, [device, history])
  const [confirmUnlinkModal, setConfirmUnlinkModal] = useState(false)
  const [secondDoorModal, setSecondDoorModal] = useState(false)

  const handleUnlinkDevice = () => {
    if (device) {
      const unlinkUuid =
        device?.address_type === 'gate'
          ? gate?.address_uuid
          : device?.address_uuid

      dispatch(unlinkDevice(uuidOfCurrentDevice, unlinkUuid)).then(() => {
        history.push(
          `/${device?.address_type === 'gate' ? 'h' : 'e'}/${unlinkUuid}`
        )
      })
    }
  }

  useEffect(() => {
    if (deviceUuid) {
      dispatch(getCamera(deviceUuid))
      dispatch(fetchIntercomByUuid(deviceUuid))
    } else {
      dispatch(fetchGate(gateAddress)).then((gate) => {
        dispatch(getCamera(gate.intercom_uuid))
        dispatch(fetchIntercomByUuid(gate.intercom_uuid))
        // TODO reserch
      })
    }

    dispatch(fetchSoundPresets())

    return () => dispatch(clearCurrentDevice())
  }, [deviceUuid, dispatch, gateAddress, gateUuid])

  const onMsgReceived = useCallback(
    (msg) => {
      if ('is_online' in msg) {
        dispatch(updateCurrentIntercomStatus(msg.is_online === 'true'))
      } else if ('door_opened' in msg) {
        dispatch(updateCurrentIntercomDoorStatus(msg.door_opened))
      }
    },
    [dispatch]
  )
  useIntercomStatus(deviceUuid || gate?.intercom_uuid, onMsgReceived)

  // settings
  const {
    value: extCom,
    bind: bindExtCom,
    setValue: setExtCom,
  } = useInput(comOptionsForV2[0])

  const {
    value: generalVol,
    bind: bindGeneralVol,
    setValue: setGeneralVol,
  } = useInput(90, () => {
    ym('reachGoal', 'control_device_soundConfig')
    ym('reachGoal', 'control_device_soundConfigGeneral', { value: generalVol })
  })

  const {
    value: speakHandsetTx,
    bind: bindSpeakHandsetTx,
    setValue: setSpeakHandsetTx,
  } = useInput(65, () => {
    ym('reachGoal', 'control_device_soundConfig')
    ym('reachGoal', 'control_device_soundConfigMic', { value: speakHandsetTx })
  })

  const {
    value: flatOffset,
    bind: bindOffsetInput,
    setValue: setOffset,
  } = useInput(0)

  const {
    value: intercomSounds,
    bind: bindIntercomSounds,
    setValue: setIntercomSounds,
  } = useInput('sputnik')

  const {
    value: openTimeGeneral,
    bind: bindOpenTimeGeneral,
    setValue: setOpenTimeGeneral,
  } = useInput(0)

  const {
    value: speakLoudspeaker,
    bind: bindSpeakLoudspeaker,
    setValue: setSpeakLoudspeaker,
  } = useInput(0)

  useEffect(() => {
    if (device?.config) {
      setOpenTimeGeneral(device.config.doors_open_durations.general)
      setExtCom(device.config.ext_com)
      setGeneralVol(device.config.sound_config.general)
      setSpeakHandsetTx(device.config.sound_config.speakHandsetTx)
      setSpeakLoudspeaker(device.config.sound_config.speakLoudspeaker)
      setIntercomSounds(device.config.current_preset)
      setOffset(device.config.flat_offset)
    }
  }, [
    device.config,
    device.address_uuid,
    setExtCom,
    setGeneralVol,
    setIntercomSounds,
    setSpeakHandsetTx,
    setOpenTimeGeneral,
    setSpeakLoudspeaker,
    setOffset,
  ])

  const parsedV1extComFromV2 = Math.max(
    comOptionsForV2.findIndex((el) => el === extCom),
    0
  )

  const handleUpdate = useCallback(() => {
    if (!device) return
    dispatch(
      updateDeviceSettings(
        uuidOfCurrentDevice,
        {
          ext_com: parsedV1extComFromV2,
          general: Number(generalVol),
          speak_handset_tx: speakHandsetTx,
          flat_offset: flatOffset,
          preset_name: intercomSounds,
          door_open_time_general: openTimeGeneral,
          speak_loudspeaker: speakLoudspeaker,
        },
        device.is_online
      )
    )
  }, [
    device,
    dispatch,
    uuidOfCurrentDevice,
    parsedV1extComFromV2,
    generalVol,
    speakHandsetTx,
    flatOffset,
    intercomSounds,
    openTimeGeneral,
    speakLoudspeaker,
  ])

  const [currentTab, setCurrentTab] = React.useState('soundSettings')

  // open time
  const [openTime, setOpenTime] = useState(5)

  const { t } = useTranslation(['devices', 'common', 'messages', 'info', 'geo'])

  const handleCloseSecondDoorModal = () => {
    setSecondDoorModal(false)
  }

  const handleOpenSecondDoorModal = () => {
    setSecondDoorModal(true)
  }

  return (
    <Dialog
      TransitionComponent={Transition}
      transitionDuration={0}
      fullScreen
      open
      onClose={handleClose}
    >
      {!device ? (
        <Loader error={devicesError} />
      ) : (
        <DeviceModalTemplate
          device={device}
          header={
            <>
              <FullscreenExitButton variant="back" onClick={handleClose} />
              <Container
                style={{
                  height: 'fit-content',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div />
                {device?.serial_number ? (
                  <Tooltip
                    title={t('devices:actions.unlink')}
                    placement="bottom"
                  >
                    <Button
                      style={{
                        top: '2.7rem',
                      }}
                      onClick={() => setConfirmUnlinkModal(true)}
                      variant="outlined"
                      color="danger"
                    >
                      {t('devices:actions.unlink_device')}
                    </Button>
                  </Tooltip>
                ) : null}
              </Container>
              <FullscreenDialogTitle
                style={{ marginTop: '2rem' }}
                subtitle={
                  device
                    ? device?.address_type === 'gate'
                      ? t('devices:gates.subtitle')
                      : t('devices:helper_text.device_modal')
                    : ''
                }
              >
                <>
                  <Tooltip title={device?.serial_number}>
                    <span>
                      <span>
                        {device?.address_type === 'gate'
                          ? t('devices:gates.gate_numbered')
                          : t('devices:intercoms.sputnik_numbered')}
                        {formatDeviceId(device?.serial_number)}
                      </span>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={
                      device.is_online
                        ? t('devices:params.online')
                        : t('devices:params.offline')
                    }
                  >
                    <span>
                      <OnlineIcon
                        isOnline={device && device.is_online}
                        style={{ marginLeft: '.325rem', width: 14, height: 14 }}
                      />
                    </span>
                  </Tooltip>
                  <Tooltip title={t('common:button.copy')}>
                    <FileCopy
                      style={{
                        width: 14,
                        height: 14,
                        marginLeft: '.325rem',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        copyToClipboard(device?.serial_number)
                        dispatch(
                          showInfoMessage(
                            t('info:copy.device', {
                              number: device?.serial_number,
                            })
                          )
                        )
                      }}
                    />
                  </Tooltip>
                </>
              </FullscreenDialogTitle>
            </>
          }
          camera={camera}
          subCamera={
            <>
              <Box
                display="flex"
                // alignItems="center"
                justifyContent="space-between"
                width="100%"
                flexWrap="wrap"
                style={{ gap: '.5rem' }}
              >
                <Box
                  display="flex"
                  alignItems="start"
                  flexDirection="column"
                  style={{ gap: '.5rem' }}
                >
                  {device?.address_type !== 'gate' ? (
                    <ControlCollectKeys />
                  ) : null}
                  <Button
                    rounded
                    secondary
                    onClick={() => dispatch(fixStreamCamera(camera.uuid))}
                  >
                    {t('devices:params.stream_sync')}
                  </Button>

                  <Button
                    rounded
                    secondary
                    onClick={handleOpenSecondDoorModal}
                    disabled={!!device?.extra_key_reader}
                  >
                    {t('devices:actions.connect_sec_door')}
                  </Button>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  style={{ gap: '.5rem' }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ gap: '1rem' }}
                  >
                    <OpenTimeSelect value={openTime} onChange={setOpenTime} />
                    <Button
                      disabled={
                        loading || device.door_opened || !device.is_online
                      }
                      variant="contained"
                      color="primary"
                      rounded
                      onClick={() =>
                        dispatch(openDoor(uuidOfCurrentDevice, openTime))
                      }
                    >
                      {device.door_opened ? (
                        <>
                          <LockOpen
                            color="inherit"
                            style={{ marginRight: 8 }}
                          />
                          {t('devices:params.opened')}
                        </>
                      ) : (
                        <>
                          <Lock color="inherit" style={{ marginRight: 8 }} />
                          {t('devices:actions.open_door')}
                        </>
                      )}
                    </Button>
                  </Box>

                  {!!device?.extra_key_reader && (
                    <OpenSecondDoorButton
                      uuid={uuidOfCurrentDevice}
                      httpRelay={device?.extra_key_reader?.http_relay}
                    />
                  )}
                </Box>
              </Box>
            </>
          }
          afterCamera={
            <>
              {device?.extra_key_reader && (
                <div style={{ marginTop: '1rem' }}>
                  <ExtraKeyReader
                    currnentDeviceUuid={uuidOfCurrentDevice}
                    kind={device?.extra_key_reader?.kind}
                    alias={device?.extra_key_reader?.alias}
                  />
                </div>
              )}
              {device?.address_type === 'gate' && (
                <div style={{ marginTop: '1rem' }}>
                  <Typography variant="h5" gutterBottom>
                    {t('geo:locations.connected_house_plural')}
                  </Typography>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns:
                        'repeat(auto-fit, minmax(200px, max-content))',
                      gridGap: '1rem',
                    }}
                  >
                    {gate?.houses?.length > 0 ? (
                      gate?.houses?.map((house) => (
                        <HouseRect {...house} key={house.uuid} />
                      ))
                    ) : (
                      <Typography>{t('messages:content.empty')}</Typography>
                    )}
                  </div>
                </div>
              )}
            </>
          }
          info={
            <>
              {device?.address_type === 'entry' && (
                <div>
                  <Typography color="textSecondary">
                    {t('devices:params.installation_point')}
                  </Typography>

                  <Typography
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    {device && device.short_address}
                  </Typography>
                </div>
              )}

              <div style={styles.field}>
                <Typography color="textSecondary">
                  {t('devices:params.installation_date')}
                </Typography>
                <Typography style={{ display: 'flex', alignItems: 'center' }}>
                  {device &&
                    (device.installation_date
                      ? formatDate(device.installation_date)
                      : t('messages:helper.unknown'))}
                </Typography>
              </div>

              <div style={styles.field}>
                <Typography color="textSecondary">HW</Typography>
                <Typography style={{ display: 'flex', alignItems: 'center' }}>
                  {device?.config?.versions?.hwVersion || 'none'}
                </Typography>
              </div>
              <div style={styles.field}>
                <Typography color="textSecondary">SW</Typography>
                <Typography style={{ display: 'flex', alignItems: 'center' }}>
                  {device?.config?.versions?.swVersion || 'none'}
                </Typography>
              </div>
              <div style={styles.field}>
                <Typography color="textSecondary">BLE</Typography>
                <Typography style={{ display: 'flex', alignItems: 'center' }}>
                  {device?.config?.versions?.bleVersion || 'none'}
                </Typography>
              </div>
            </>
          }
          tabs={
            <Tabs onChange={(e, tab) => setCurrentTab(tab)} value={currentTab}>
              {tabs.map((tab) => (
                <Tab
                  disableRipple
                  label={t(`devices:params.${tab.label}`)}
                  value={tab.value}
                  key={tab.value}
                />
              ))}
            </Tabs>
          }
        >
          <form style={styles.configForm}>
            {currentTab === 'soundSettings' && (
              <>
                <Row style={styles.field}>
                  <TextField
                    label={t('devices:params.volume')}
                    inputProps={{
                      type: 'number',
                      min: 1,
                      max: 100,
                    }}
                    placeholder="85"
                    helperText={`${t('messages:helper.recommended')}: 85`}
                    style={styles.field}
                    {...bindGeneralVol}
                  />
                  <TextField
                    label={t('devices:params.mic')}
                    inputProps={{
                      type: 'number',
                      min: 1,
                      max: 100,
                    }}
                    {...bindSpeakHandsetTx}
                    placeholder="65"
                    style={styles.field}
                    helperText={`${t('messages:helper.recommended')}: 65`}
                  />
                </Row>
                <TextField
                  label={t('devices:params.load_speaker')}
                  inputProps={{
                    type: 'number',
                    min: 1,
                    max: 100,
                  }}
                  {...bindSpeakLoudspeaker}
                  placeholder="65"
                  style={styles.field}
                  helperText={`${t('messages:helper.recommended')}: 85`}
                />
                <FormControl style={styles.field}>
                  <InputLabel htmlFor="sound_presets">
                    {t('devices:params.sound_preset')}
                  </InputLabel>
                  <Select
                    {...bindIntercomSounds}
                    inputProps={{
                      name: 'sound_presets',
                      id: 'sound_presets',
                    }}
                  >
                    {soundPresets &&
                      soundPresets.map(({ name, locale_name }) => (
                        <MenuItem value={name} key={name}>
                          {locale_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </>
            )}

            {currentTab === 'commutatorSettings' && (
              <>
                <FormControl style={styles.field}>
                  <InputLabel htmlFor="ext_com">
                    {t('devices:params.com_type')}
                  </InputLabel>
                  <Select
                    {...bindExtCom}
                    inputProps={{
                      name: 'ext_com',
                      id: 'ext_com',
                    }}
                  >
                    {comOptions.map(({ value, label }, idx) => (
                      <MenuItem value={comOptionsForV2[value]} key={value}>
                        {t('devices:params.com_interval', {
                          count: idx,
                          postProcess: 'interval',
                        })}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  label={t('devices:params.flat_offset')}
                  type="number"
                  style={styles.field}
                  inputProps={{
                    type: 'number',
                    min: -1000,
                    max: 1000,
                  }}
                  {...bindOffsetInput}
                  helperText={
                    <a href="https://help.sputnik.systems/ru/articles/3637638-%D1%81%D0%BC%D0%B5%D1%89%D0%B5%D0%BD%D0%B8%D0%B5-%D0%B4%D0%B8%D0%B0%D0%BF%D0%B0%D0%B7%D0%BE%D0%BD%D0%B0">
                      {t('common:what_is')}
                    </a>
                  }
                />

                <CommutatorScheme commutator={parsedV1extComFromV2} />
              </>
            )}

            {currentTab === 'door_settings' && (
              <>
                <TextField
                  label={t('devices:params.doorSettings')}
                  type="number"
                  style={styles.field}
                  {...bindOpenTimeGeneral}
                  inputProps={{
                    type: 'number',
                    min: 1,
                    max: 65535,
                  }}
                />
              </>
            )}

            <Button
              primary
              variant="contained"
              rounded
              style={{ marginLeft: 'auto', marginTop: '1rem' }}
              onClick={handleUpdate}
              disabled={loading}
            >
              {t('common:button.update_settings')}
              {loading && '...'}
            </Button>
          </form>

          <ConfirmActionModal
            open={confirmUnlinkModal}
            action={t('devices:actions.unlink')}
            handleAction={handleUnlinkDevice}
            handleClose={() => setConfirmUnlinkModal(false)}
          />

          <ConnectSecondDoorModal
            currnentDeviceUuid={uuidOfCurrentDevice}
            serial={device?.serial_number}
            open={secondDoorModal}
            onClose={handleCloseSecondDoorModal}
          />
        </DeviceModalTemplate>
      )}
    </Dialog>
  )
}

const mapStateToProps = ({ devices, gates }) => ({
  camera: devices.camera,
  device: devices.intercom || {},
  devicesError: devices.error,
  loading: devices.loading,
  soundPresets: devices.soundPresets,
  gate: gates.current,
})

const comOptions = [
  {
    label: 'Метаком',
    value: 0,
    // nextLabelValue_v2: 'METACOM',
  },
  {
    label: 'Визит',
    value: 1,
  },
  {
    label: 'Цифрал',
    value: 2,
  },
  { label: 'Элтис', value: 3 },
]

const comOptionsForV2 = ['METACOM', 'VIZIT', 'CYFRAL', 'ELTIS']

const Row = styled.div`
  display: flex;
  margin-bottom: 1rem;

  & > * {
    flex: 1;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    flex-direction: column;
    & > * {
      margin-right: 0;
    }
  }
`

const styles = {
  field: {
    marginBottom: '.625rem',
  },
  configForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  iconField: {
    marginRight: '.625rem',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  verField: {
    display: 'flex',
    height: '51.43px',
    // height px иначе iconFiled hight 100% не отрабатывает
    marginBottom: '.625rem',
  },
}

const tabs = [
  {
    label: 'sound_settings',
    value: 'soundSettings',
  },
  {
    label: 'com_settings',
    value: 'commutatorSettings',
  },
  {
    label: 'door_settings',
    value: 'door_settings',
  },
]

function Transition(props) {
  return <Fade direction="up" {...props} />
}

export default connect(mapStateToProps)(withRouter(DeviceModal))
